import { Fontes } from "../api";

export function rebuildFilters(items: Fontes): Fontes {
    return Object.keys(items)
        .filter(key => items[key].ativo)
        .sort((keyA, keyB) => items[keyA].order - items[keyB].order)
        .reduce((acc: Fontes, key: string) => {
            acc[key] = {
                ...items[key],
                sigla: key,
                selected: true,
            };
            return acc;
        }, {});
}