import { QuestionExamples } from "../api";

interface Citation {
	citation: string;
	metadata: any;
	page_number: string;
	source_path: string;
}

export function removeDuplicatesByCitation(arr: any) {
	const seen = new Set();
	return arr.filter((obj: any) => {
		if (seen.has(obj.name)) {
			return false;
		}
		seen.add(obj.name);
		return true;
	});
}

export function removeDuplicatesBySourcePath(arr: any) {
	const seen = new Set();
	return arr.filter((obj: any) => {
		if (seen.has(obj.source_path)) {
			return false;
		}
		seen.add(obj.source_path);
		return true;
	});
}

export function removeDuplicateCitation(citations: Record<string, Citation>): Record<string, Citation> {
	const seenPaths: Set<string> = new Set();
	const uniqueCitations: Record<string, Citation> = {};

	for (const [fileName, citation] of Object.entries(citations)) {
		const citationPath = citation.citation;

		// Ignora se já visto ou se não tem source_path
		if (seenPaths.has(citationPath) || !citationPath) continue;

		// Adiciona o source_path aos vistos
		seenPaths.add(citationPath);

		// Inclui a citação completa no objeto de resultados
		uniqueCitations[fileName] = citation;
	}

	return uniqueCitations;
}

export function removeDuplicateSourcePaths(citations: Record<string, Citation>): Record<string, Citation> {
	const seenPaths: Set<string> = new Set();
	const uniqueCitations: Record<string, Citation> = {};

	for (const [fileName, citation] of Object.entries(citations)) {
		const sourcePath = citation.source_path;

		// Ignora se já visto ou se não tem source_path
		if (seenPaths.has(sourcePath) || !sourcePath) continue;

		// Adiciona o source_path aos vistos
		seenPaths.add(sourcePath);

		// Inclui a citação completa no objeto de resultados
		uniqueCitations[fileName] = citation;
	}

	return uniqueCitations;
}

export function getKeysAndValues(obj: object): Array<[string, any]> | null {
	if (!obj || obj == null) 
		return null;

	const keys = Object.keys(obj);
	const values = Object.values(obj);
	const results: Array<[string, any]> = [];

	for (let i = 0; i < keys.length; i++) {
		results.push([keys[i], values[i]]);
	}

	return results;
}

export function sortFiltersByOrder(obj: any) {
    if (obj && obj.filters && obj.filters.fontes) {
        const fontes = obj.filters.fontes;
        const sortedFontes: any = {};

        Object.keys(fontes)
            .sort((a: string, b: string) => fontes[a].order - fontes[b].order)
            .forEach((key: string) => {
                sortedFontes[key] = fontes[key];
            });

        obj.filters.fontes = sortedFontes;
    }
    return obj;
};

export function sortQuestionsByOrder(arr: QuestionExamples[]) {
    return arr.sort((a, b) => a.order - b.order);
};